import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'

import { Provider } from 'react-redux'
import { ThemeProvider, StylesProvider } from '@material-ui/styles'
import { StyledEngineProvider } from '@mui/material/styles'
import './bootstrap'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { CookiesProvider, Cookies } from 'react-cookie'
import TagManager from 'react-gtm-module'
import {
  ThemeProvider as ReferaThemeProvider,
  ConfirmContextProvider,
  LocalizationProvider,
  AdapterMoment,
} from '@refera/ui-web'
import ReferaTheme from '@refera/ui-core'

import { GTM_ID, APP_VERSION } from '_config/environment'
import { User } from '_models'
import { COOKIES_OPTIONS } from '_utils/constants'
import { theme } from '_utils/material-ui'

import configureStore from './store/configure-store'
import Router from './router'
import { ToastProvider } from './hooks/use-toast'
import { DialogProvider } from './hooks/use-dialog'

const cookies = new Cookies()
const rootElement = document.getElementById('root')

const authToken = cookies.get(COOKIES_OPTIONS.name, { path: COOKIES_OPTIONS.path })
const initialState = {
  user: new User({
    authToken,
  }),
}
const store = configureStore(initialState)

if (GTM_ID) {
  TagManager.initialize({
    gtmId: GTM_ID,
  })
}

function checkVersion() {
  const currentVersion = cookies.get('appVersion')
  if (currentVersion !== APP_VERSION) {
    cookies.set('appVersion', APP_VERSION)
    window.location.reload()
  }
}

function inIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

if (!inIframe) checkVersion()

const root = createRoot(rootElement)

function App() {
  useEffect(() => {
    // Remove o loader inline da aplicação
    const initialLoader = document.getElementById('initial-loader')
    if (initialLoader) {
      initialLoader.remove()
    }
  }, [])

  return (
    <Provider store={store}>
      <StylesProvider injectFirst>
        <StyledEngineProvider injectFirst>
          <ReferaThemeProvider theme={ReferaTheme}>
            <ThemeProvider theme={theme}>
              <ConfirmContextProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <CookiesProvider>
                      <DialogProvider>
                        <ToastProvider>
                          <Router />
                        </ToastProvider>
                      </DialogProvider>
                    </CookiesProvider>
                  </MuiPickersUtilsProvider>
                </LocalizationProvider>
              </ConfirmContextProvider>
            </ThemeProvider>
          </ReferaThemeProvider>
        </StyledEngineProvider>
      </StylesProvider>
    </Provider>
  )
}

root.render(<App />)
